<template v-if="project">
    <span class="is-size-5"><strong>Cement (CEM-IV) Total Required</strong></span>
        <p class="is-size-5">{{ project.cement_used }} of {{ project.cement_total }} bags</p>
        <progress class="progress is-primary" :value="project.cement_used / project.cement_total * 100" max="100">
        </progress>
        <p class="is-size-5">{{ Math.ceil(project.cement_used / project.cement_total * 100) }}% Complete </p>
        <h3 class="is-size-5"><strong>Cement (CEM-IV) Stock</strong></h3>
        <p class="is-size-5">{{ Math.ceil(project.cement_used_stock / project.cement_in_stock * 100) }}% Complete </p>
        <p class="is-size-5">{{ project.cement_used_stock }} of {{ project.cement_in_stock }} bags</p>
        <progress class="progress is-primary" :value="project.cement_used_stock / project.cement_in_stock * 100" max="100">
        </progress>
        <div class="field mt-3">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContentResetStockCem4">
              <label class="label">Reset Stock</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContentResetStockCem4">
              <span class="arrow-icon" :class="{ 'up': showContentResetStockCem4, 'down': !showContentResetStockCem4 }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContentResetStockCem4">
              <button class="button is-success" type="submit" @click="resetStockCem4()">Reset Stock</button>
          </div>
        </div>
        
        <div v-if="project.cement_cem2_total">
          <span class="is-size-5"><strong>Cement (CEM-II) Total Required</strong></span>
          <p class="is-size-5">{{ project.cement_cem2_used }} of {{ project.cement_cem2_total }} bags</p>
          <progress class="progress is-primary" :value="project.cement_cem2_used / project.cement_cem2_total * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.cement_cem2_used / project.cement_cem2_total * 100) }}% Complete </p>
          <h3 class="is-size-5"><strong>Cement (CEM-II) Stock</strong></h3>
          <p class="is-size-5">{{ Math.ceil(project.cement_cem2_used_stock / project.cement_cem2_in_stock * 100) }}% Complete </p>
          <p class="is-size-5">{{ project.cement_cem2_used_stock }} of {{ project.cement_cem2_in_stock }} bags</p>
          <progress class="progress is-primary" :value="project.cement_cem2_used_stock / project.cement_cem2_in_stock * 100" max="100">
          </progress>
          <div class="field mt-3">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContentResetStockCem2">
              <label class="label">Reset Stock</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContentResetStockCem2">
              <span class="arrow-icon" :class="{ 'up': showContentResetStockCem2, 'down': !showContentResetStockCem2 }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContentResetStockCem2">
              <button class="button is-success" type="submit" @click="resetStockCem2()">Reset Stock</button>
          </div>
          </div>
        </div>
        <hr>
        <div class="field mt-3">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContentCem4Used">
              <label class="label">Update Cement (CEM-IV) Used</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContentCem4Used">
              <span class="arrow-icon" :class="{ 'up': showContentCem4Used, 'down': !showContentCem4Used }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContentCem4Used">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="cement_quantity">
              <button class="button is-success" type="submit" @click="updateCement()">Update Cement (CEM-IV) Used</button>
          </div>
        </div>
        <div class="field" v-if="project.cement_cem2_total">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContentCem2Used">
              <label class="label">Update Cement (CEM-II) Used</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContentCem2Used">
              <span class="arrow-icon" :class="{ 'up': showContentCem2Used, 'down': !showContentCem2Used }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContentCem2Used">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="cement_cem2_quantity">
              <button class="button is-success" type="submit" @click="updateCementCem2()">Update Cement (CEM-II) in Used</button>
          </div>
        </div>
        <div class="field">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContent">
              <label class="label">Update Cement (CEM-IV) Stock</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContent">
              <span class="arrow-icon" :class="{ 'up': showContent, 'down': !showContent }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContent">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="cement_in_stock">
              <button class="button is-success" type="submit" @click="updateCementStock()">Update Cement (CEM-IV) in Stock</button>
          </div>
        </div>
        <div class="field" v-if="project.cement_cem2_total">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContentCement">
              <label class="label">Update Cement (CEM-II) in Stock</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContentCement">
              <span class="arrow-icon" :class="{ 'up': showContentCement, 'down': !showContentCement }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContentCement">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="cement_cem2_in_stock">
              <button class="button is-success" type="submit" @click="updateCementCem2Stock()">Update Cement (CEM-II) in Stock</button>
          </div>
        </div>
    <hr>
    <span class="is-size-5"><strong>Stone Dust</strong></span>
        <p class="is-size-5">{{ project.stone_dust_used }} of {{ project.stone_dust_total }} tonnes</p>
        <progress class="progress is-primary" :value="project.stone_dust_used / project.stone_dust_total * 100" max="100">
        </progress>
        <p class="is-size-5">{{ Math.ceil(project.stone_dust_used / project.stone_dust_total * 100) }}% Complete </p>
        <!--
          <div class="field">
          <label class="label">Update stone dust used</label>
          <div class="control">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="stone_dust_quantity">
              <button class="button is-success" type="submit" @click="updateStoneDust()">Update Stone Dust</button>
          </div>
          </div>
          <div class="field">
            <div class="columns is-12 is-mobile">
              <div class="column is-10" @click="toggleContentStone">
                <label class="label">Update Stone Dust Stock</label>
              </div>
              <div class="arrow-container column is-2" @click="toggleContentStone">
                <span class="arrow-icon" :class="{ 'up': showContentStone, 'down': !showContentStone }"></span>
              </div>
            </div>
            <div class="control content" v-show="showContentStone">
                <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="stone_dust_in_stock">
                <button class="button is-success" type="submit" @click="updateStoneDustStock()">Update Stone dust in Stock</button>
            </div>
          </div>
        -->
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'Material',
    props: {
        project:Object
    },
    data() {
      return {
        cement_quantity:'',
        cement_cem2_quantity:'',
        stone_dust_quantity:'',
        cement_in_stock: '',
        cement_cem2_in_stock:'',
        stone_dust_in_stock: '',
        showContent: false,
        showContentStone: false,
        showContentCement: false,
        showContentCem2Used: false,
        showContentCem4Used:false,
        showContentResetStockCem2:false,
        showContentResetStockCem4: false,
        errors:[]
      };
    },
    methods:{
        resetStockCem4(){

          axios.post(`/api/v1/projects/${this.project.id}/reset-cement-cem4`,)
                .then(response => {
                  toast({
                  message: 'The Cement (CEM-IV) Stock used was successfully reset',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
                })
                .catch(error => {

                })
        },
        resetStockCem2(){
          axios.post(`/api/v1/projects/${this.project.id}/reset-cement-cem2`,)
                .then(response => {
                  toast({
                  message: 'The Cement (CEM-II) Stock used was successfully reset',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
                })
                .catch(error => {

                })
          },
        updateCement(){
        if (isNaN(this.cement_quantity) || this.cement_quantity < 1 ){
          this.cement_quantity = 0
        }
        this.errors = []

        const data1 = {
          cement_used: this.project.id,
          quantity: this.cement_quantity
        }

        const data = { number: this.cement_quantity} 
          //console.log(data1)
          axios
            .post('/api/v1/upload_cement_used/', data1)
            .then(response => {
              //console.log(response)
            })
            .catch(error => {

            });  
        //console.log(data
        axios.post(`/api/v1/projects/${this.project.id}/update-cement-used-stock`, data)
            .then(response => {
              this.cement_quantity = ''
              toast({
                  message: 'The Cement (CEM-IV) used was successfully added',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              //console.log(response);
            })
            .catch(error => {
              if (error.response) {
                for (const property in error.response.data) {
                    this.errors.push(`${error.response.data[property]}`)
                }
              } else {
                  this.errors.push('Something went wrong. Please try again')   
                  console.log(JSON.stringify(error))
              }
            });
      },

      updateCementCem2(){
        if (isNaN(this.cement_cem2_quantity) || this.cement_cem2_quantity < 1){
                this.cement_cem2_quantity = 0
        }
        const data = { number: this.cement_cem2_quantity} 
        //console.log(data)
        const data1 = {
            cement_cem2_used: this.project.id,
            quantity: this.cement_cem2_quantity
          }
        //console.log(data1)
        axios
          .post('/api/v1/upload_cement_cem2_used/', data1)
          .then(response => {
            //console.log(response)
          })
        axios.post(`/api/v1/projects/${this.project.id}/update-cement-cem2-used-stock`, data)
            .then(response => {
              this.cement_cem2_quantity = ''
              toast({
                  message: 'The Cement (CEM-II) used was successfully added',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              //console.log(response);
            })
            .catch(error => {
              //console.log(error);
            });
      },

      updateCementStock(){
        const data = { number: this.cement_in_stock}
        axios.post(`/api/v1/projects/${this.project.id}/update-cement-stock`, data)
            .then(response => {
              this.cement_quantity = ''
              toast({
                  message: 'The Cement (CEM-IV) used was successfully added',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              //console.log(response);
            })
            .catch(error => {
             // console.log(error);
            });
      },

      updateCementCem2Stock(){
        const data = { number: this.cement_cem2_in_stock}
        axios.post(`/api/v1/projects/${this.project.id}/update-cement-cem2-stock`, data)
            .then(response => {
              this.cement_cem2_quantity = ''
              toast({
                  message: 'The Cement (CEM-II) used was successfully added',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              //console.log(response);
            })
            .catch(error => {
             // console.log(error);
            });
      },
      
      /*

      updateCementStock(){
        const socket = new WebSocket(`ws://${window.location.host}/ws/project/${this.project.id}/update-cement`)
        console.log(socket)

        socket.onmessage = function(e){console.log(e.data);}

        socket.onopen = () => {
          console.log("connected")
          resolveComponent(socket)
        }

        socket.send(JSON.stringify({
          "message": this.cement_in_stock
        }))

        function sendMessage(msg) {
        waitForSocketConnection(nvWS, function() {
            ws.send(msg);
        });
        };


        function waitForSocketConnection(socket, callback){
        setTimeout(
            function(){
                if (socket.readyState === 1) {
                    if(callback !== undefined){
                        callback();
                    }
                    return;
                } else {
                    waitForSocketConnection(socket,callback);
                }
            }, 5);
        };
        },

        */
      updateStoneDust(){
        if (isNaN(this.stone_dust_quantity) || this.stone_dust_quantity < 1){
                this.stone_dust_quantity = 0
        }
        const data = { number: this.stone_dust_quantity} 
        //console.log(data)
        const data1 = {
            stone_dust_used: this.project.id,
            quantity: this.stone_dust_quantity
          }
        //console.log(data1)
        axios
          .post('/api/v1/upload_stone_dust_used/', data1)
          .then(response => {
            //console.log(response)
          })
        axios.post(`/api/v1/projects/${this.project.id}/update-stone-dust`, data)
            .then(response => {
              this.stone_dust_quantity = ''
              toast({
                  message: 'The Stone dust used was successfully added',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              //console.log(response);
            })
            .catch(error => {
              //console.log(error);
            });
      },

      updateStoneDustStock(){
        const data = { number: this.stone_dust_in_stock}
        axios.post(`/api/v1/projects/${this.project.id}/update-stone-dust-stock`, data)
            .then(response => {
              this.stone_dust_in_stock = ''
              toast({
                  message: 'The Stone Dust stock used was successfully updated',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              //console.log(response);
            })
            .catch(error => {
              //console.log(error);
            });
      },

      toggleContent(){
        this.showContent = !this.showContent;
      },
      toggleContentStone(){
        this.showContentStone = !this.showContentStone;
      },
      toggleContentCement(){
        this.showContentCement = !this.showContentCement;
      },
      toggleContentCem2Used(){
        this.showContentCem2Used = !this.showContentCem2Used;
      },
      toggleContentCem4Used(){
        this.showContentCem4Used = !this.showContentCem4Used;
      },
      toggleContentResetStockCem2(){
        this.showContentResetStockCem2 = !this.showContentResetStockCem2
      },
      toggleContentResetStockCem4(){
        this.showContentResetStockCem4 = !this.showContentResetStockCem4
      }

    }
}
</script>

<style scoped>
.arrow-icon {
  display: inline-block;
  border: solid black;
  border-width: 0 3px 3px 0;
  padding: 3px;
  margin-right: 10px;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out;
}

.arrow-icon.up {
  transform: rotate(-135deg);
}

.arrow-icon.down {
  transform: rotate(45deg);
}

</style>

