<template>
  <div>
    <TopBar />
    <vueper-slides ref="myVueperSlides" :parallax="parallax" :parallax-fixed-content="parallaxFixedContent" fixed-height="500px" >
        <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :title="slide.title"
            :content="slide.content"
            :image="slide.image">
            <template #content>
                <div class="vueperslide__content-wrapper parallxFixedContent has-text-white" style="background-color:rgba(0,0,0,0.5);">
                    <span style="font-size:36px">{{slide.title}}</span>
                    <span style="font-size:22px">{{slide.content}}</span>

                </div>
            </template>
            
        </vueper-slide>
        </vueper-slides>

    <div class="section ">
    <div class="section-title">
        <router-link to ='/projects'>
            <a class="button is-success" style="background-color:#004100;">Go to Projects</a>
        </router-link>
    </div>
    </div>
    <div class="section pt-3">
      <div class="section-title">
          <h1 class=""><strong>How it Works</strong></h1>
      </div>
      <div class="row">
        <div class="column is-4 box m-2 icon-box">
            <span class="icon mb-2"><i class="fa fa-industry" aria-hidden="true" style="color:white"></i></span>
            <div class="content">
                <h4>Upload site status report</h4>
                <p>Take a photo of the site where you are going to lay the blocks. Upload a picture of machine mobilisation and set up.</p>
            </div>
        </div>
        <div class="column is-4 box m-2 icon-box">
            <span class="icon mb-2"><i class="fa fa-building" aria-hidden="true" style="color:white"></i></span>
            <div class="content">
                <h4>Upload Daily Block production</h4>
                <p>After a day's work, upload the number of blocks made for each type under each project
                </p>
            </div>
        </div>
        <div class="column is-4 box m-2 icon-box">
            <span class="icon mb-2"><i class="fa fa-list-alt" aria-hidden="true" style="color:white"></i></span>
            <div class="content">
                <h4>Client tracks your progress</h4>
                <p>The client will view the number of blocks you have made per day, and can also
                  track production.
                </p>
            </div>
        </div>
        
      </div>
    </div>    
  </div>
  
</template>

<script>
import axios from 'axios';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import TopBar from '@/components/TopBar.vue';

export default {
  name: 'HomeView',
  data: () => ({
        parallax: 1,
        parallaxFixedContent: false,
        slides: [
            {
            title: 'Welcome to Tofali Operator',
            content: 'Upload daily production of blocks',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478568/t9di9gttcruybx2ljpmm.jpg'
            },
            {
            title: 'Allow Clients to track production',
            content: 'Ensures efficient monitoring of materials used: cement and stone dust',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478763/xxdcfayopd2tfxefvcrx.jpg'
            }
  ]
  }),
  components: {
      VueperSlide,
      VueperSlides,
      TopBar
  },
  methods: {
  }
}
</script>
