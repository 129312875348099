<template>
    <ul>
        <li>
            <div class="columns is-mobile">
            <div class="column is-6 mb-4">
                <p class="block-image skeleton"></p>
                <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            </div>
            
            <div class="column is-6 pt-5 field"> 
                <p class="skeleton skeleton-text skeleton-text__body pt-5"></p>
            </div>

        </div>
        <hr class="mt-0">
        </li>
        <li>
            <div class="columns is-mobile">
            <div class="column is-6 mb-4">
                <p class="block-image skeleton"></p>
                <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            </div>
            
            <div class="column is-6 pt-5 field"> 
                <p class="skeleton skeleton-text skeleton-text__body pt-5"></p>
            </div>

        </div>
        <hr class="mt-0">
        </li>
        <li>
            <div class="columns is-mobile">
            <div class="column is-6 mb-4">
                <p class="block-image skeleton"></p>
                <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            </div>
            
            <div class="column is-6 pt-5 field"> 
                <p class="skeleton skeleton-text skeleton-text__body pt-5"></p>
            </div>

        </div>
        <hr class="mt-0">
        </li>
        <li>
            <div class="columns is-mobile">
            <div class="column is-6 mb-4">
                <p class="block-image skeleton"></p>
                <p class="skeleton skeleton-text skeleton-text__body mt-2"></p>
            </div>
            
            <div class="column is-6 pt-5 field"> 
                <p class="skeleton skeleton-text skeleton-text__body pt-5"></p>
            </div>

        </div>
        <hr class="mt-0">
        </li>
        

    </ul>
</template>

<script>

</script>

<style scoped>
.block-image{
  width: 100%;
  height: 50px;
  padding: 5px;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

</style>