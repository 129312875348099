import { createStore } from 'vuex'

export default createStore({
  state: {
    username:'',
    isAuthenticated: false,
    access: '',
    refresh: '',
    projects:[],
    blocks:[],
    blocks_made:[],
  },
  getters: {
    projects(state){
      return state.projects
    },
    blocks(state){
      return state.blocks
    },
    blocks_made(state){
      return state.blocks_made
    }

  },
  mutations: {
    initializeStore(state){
      if(localStorage.getItem('refresh')){
        state.refresh = localStorage.getItem('refresh')
      }else{
        state.refresh = ''
      }
      if(localStorage.getItem('access')){
          state.access = localStorage.getItem('access')
          //state.refresh = localStorage.getItem('refresh')
          state.isAuthenticated = true
      }else{
          state.access = ''
          //state.refresh = ''
          state.isAuthenticated = false
      }

      if(localStorage.getItem('username')){
          state.username = localStorage.getItem('username')
          //state.isAuthenticated = true
      }else{
          //localStorage.setItem('username', state.username)
          //state.isAuthenticated = false
      }
    },
    updateBlock(state, updatedBlock) {
      const index = state.blocks.findIndex(block => block.id === updatedBlock.id);
      if (index !== -1) {
        state.blocks.splice(index, 1, updatedBlock);
      }
    },
    setAccess(state, access){
      state.access = access
      state.isAuthenticated = true
    },
    setRefresh(state, refresh){
      state.refresh = refresh
    },
    setUsername(state, username){
      state.username = username
      state.isAuthenticated = true
      localStorage.setItem('username', state.username)
    },
    removeAccess(state) {
      state.access = ''
      state.isAuthenticated = false
    },
    removeUsername(state){
      state.username = ''
      state.isAuthenticated = false
    },
    setProjects(state, projects){
      state.projects = projects
    },
    setBlocks(state, blocks){
      state.blocks = blocks
    },
    setBlocksMade(state, blocks_made){
      state.blocks_made = blocks_made
    },
  },
  actions: {
    
  },
  modules: {
  }
})
