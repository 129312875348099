<template>
    <TopName name="Projects" />
    <div class="section pt-6 ml-0">
    <template v-if="this.$store.state.projects != ''">
        <ul v-for="(project) in this.$store.state.projects"
            v-bind:key ="project.id"
            v-bind:project="project">
          <div class="box mb-2 mt-3 has-text-left">
            <h2 class="is-size-4"><strong>{{project.name}}</strong></h2>
            
              <Material
                v-bind:key="project.id"
                v-bind:project="project"
              />
              <hr>
            
            <!--
              <span class="is-size-5">Cement</span>
          <p class="is-size-5">{{ project.cement_used }} of {{ project.cement_total }}</p>
          <progress class="progress is-primary" :value="project.cement_used / project.cement_total * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.cement_used / project.cement_total * 100) }}% Complete </p>
          <div class="field">
              <label class="label">Update Cement Used</label>
              <div class="control">
                  <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="cement_quantity">
                  <button class="button is-success" type="submit" @click="updateCement()">Update Cement</button>
              </div>
          </div>
      <hr>
      <span class="is-size-5"><strong>Stone Dust</strong></span>
          <p class="is-size-5">{{ project.stone_dust_used }} of {{ project.stone_dust_total }}</p>
          <progress class="progress is-primary" :value="project.stone_dust_used / project.stone_dust_total * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.stone_dust_used / project.stone_dust_total * 100) }}% Complete </p>
          <div class="field">
          <label class="label">Update stone dust used</label>
          <div class="control">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="stone_dust_quantity">
              <button class="button is-success" type="submit" @click="updateStoneDust()">Update Stone Dust</button>
          </div>
          </div>
      <hr>
    -->

            <span class="is-size-5"><strong>Blocks Production</strong></span>
                <Block
                v-for="(block) in this.$store.state.blocks.filter(block => block.project === project.id)"
                v-bind:key="block.id"
                v-bind:block="block"
                />
          </div>
          </ul>
      </template>
      <template v-else>
        <div class="box">
          <h4 class="subtile">Your active projects to update will appear here</h4>
        </div>
        <ProjectLoading />
      </template>
    </div>
</template>

<script>
import axios from 'axios'
import TopName from '@/components/TopName.vue';
import ProjectLoading from '@/components/ProjectLoading.vue'
import Block from '@/components/Block.vue';
import Material from '@/components/Material.vue';
import { toast } from 'bulma-toast'

export default{
    name: 'Projects',
    data(){
      return{
        project:[],
        block:{},
        quantity: '',
        cement_quantity:'',
        stone_dust_quantity:'',
        updateBlocks:0
      }
    },
    props:{
        
    },
    components:{
    TopName,
    ProjectLoading,
    Block,
    Material
},
    mounted(){
    setInterval(() => {
      this.getProjects()
      this.getBlocks()
      this.getBlocksMade()
    }, 5000)

  },
  created() {
    this.getProjects();
    this.getBlocks();
  },
    methods:{
        getProjects(){
        axios.get('/api/v1/op_projects/')
            .then(response => {
              //console.log(response.data)
              this.$store.commit("setProjects", response.data)
            })
            .catch((err) => console.error(err));
    },
        getBlocks(){
         axios
          .get('/api/v1/blocks/')
          .then(response => {
            //console.log(response.data)
            this.$store.commit("setBlocks", response.data)     
          })
          .catch((err) => console.error(err));
    },
    getBlocksMade(){
      axios
          .get('/api/v1/blocks_made')
          .then(response => {
            //console.log(response.data)
            this.$store.commit("setBlocksMade", response.data)
          })
          .catch((err) => console.error(err));
    },
  } 
}
</script>

<style scoped>
.section{
  overflow-x: hidden;
}
td{
  padding-left: 2px;
  padding-right: 2px;
}

</style>

<style scoped lang="scss">

</style>