<template>
  <div id="op">
  <router-view/>

  <div class="navigation" style="margin-top: 80px;">
  
  <div class="nav-box">
  <router-link to="/">
      <p>
      <span><i class="icofont-ui-home"></i></span>
       <span>Home</span>
       </p>
      </router-link>
      </div>
      
  <div class="nav-box">
    <router-link to="/projects">
      <p>
        <span><i class="icofont-ui-file"></i></span>
        <span>Projects</span>
      </p>
    </router-link>
  </div>
  <div class="nav-box">
      <router-link to="/about">
      <p>
        <span><i class="icofont-ui-user"></i></span>
        <span>My account</span>
      </p>
    </router-link>
    </div>
</div>
  </div>
</template>

<script>
import axios from 'axios';
export default{
  beforeCreate(){
    this.$store.commit('initializeStore')

    const access = this.$store.state.access

    if( access ){
      axios.defaults.headers.common['Authorization'] = "JWT " + access
    }else{
      axios.defaults.headers.common['Authorization'] = '' 
    }
  },
  mounted(){
    this.cart = this.$store.state.cart
    setInterval(()=> {
      this.getAccess()
    }, 5000)
  },
  methods: {
    getAccess(){
      const accessData = {
        refresh: this.$store.state.refresh
      }

      axios
        .post('/api/v1/jwt/refresh/', accessData)
        .then(response => {
          const access = response.data.access

          //console.log(access)

          localStorage.setItem("access", access)
          this.$store.commit('setAccess', access)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
}
</script>


<style lang="scss">
@import '../node_modules/bulma';


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#op{
  overflow-x: hidden;
}

.navbar-brand {
  min-height: 3.25rem;
  display: flex;
}

nav {
  a {
    &.router-link-exact-active {
      color: rgb(0, 65, 0);
    }
  }
}

.is-success{
    background-color:#004100;
}

.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.lds-dual-ring:after {
  content: " ";
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  margin: 8px;
  border-radius: 50%;
  border: 10px solid #162534;
  border-top-color: #4bc8eb;
  border-bottom-color: #f13a8f;
  animation: lds-dual-ring 5s linear infinite;
}

.loader-inner{
  border-top-color: #36f372;
  border-bottom-color: #fff;
  animation-duration: 2.5s;
}

@keyframes lds-dual-ring {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50%{
    transform: scale(.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}


.is-loading-bar {
  height: 0;
  overflow: hidden;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 65px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 567px){
#footer .widgetized-footer {
    padding: 40px 0 15px 0;
}}
@media only screen and (min-width: 769px){
#footer .footer-widget h3.widget-title {
    margin-bottom: 10px;
}
.row{
  display: flex;
}
}

img.mb-1 {
    max-width: 200px;
}

img{
  vertical-align: bottom;
  height: auto;
  border: 0;
}

.navigation{
  width :100%;
   z-index :100;
   position :fixed;
   //line-height: .4rem;
   bottom:  0;
   height : 3.25rem;
   background-color: #ffffff;
   opacity :0.8rem;
   left: 0;
   border-top : .02rem solid #eee;
   display: flex;
   font-size :.2rem;
}
   .nav-box{
      flex: 1;
      width:  20%;
      text-align: center;
      font-size: 1rem;
      color: #ffffff;
      display:  flex;
      flex-direction :column;
      justify-content: center;
      justify-items: center;
   }
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        color: #004100;
      }
        span{
          flex: 1;
        }


</style>
