<template>
    <TopName name="Join Tofali Africa" />
    <div class="section page-sign-up">
        <div class="columns has-text-left">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Sign up</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" class="input" v-model="email">
                        </div>
                    </div>
                    <div class="field">
                        <label>First Name</label>
                        <div class="control">
                            <input type="text" class="input" v-model="first_name">
                        </div>
                    </div>
                    <div class="field">
                        <label>Last Name</label>
                        <div class="control">
                            <input type="text" class="input" v-model="last_name">
                        </div>
                    </div>
                    <div class="field">
                        <label>Password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="field">
                        <label>Repeat password</label>
                        <div class="control">
                            <input type="password" class="input" v-model="password2">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button type="submit" class="button is-success" style="background-color:#004100;">Sign up</button>
                        </div>
                    </div>
                    <p>By signing up you agree to Tofali Africa's <router-link to="/privacy-policy">Privacy Policy, Terms and Conditions</router-link></p>

                    <hr>

                    Or <router-link to="/log-in" class="is-size-5">click here</router-link> to log in!
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import TopName from '@/components/TopName.vue'

export default {
    name: 'SignUp',
    data() {
        return {
            email:'',
            first_name: '',
            last_name: '',
            password: '',
            password2: '',
            errors: []
        }
    },
    components:{
        TopName
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.email === '') {
                this.errors.push('Email is missing')
            }
            if (this.firstname === '') {
                this.errors.push('The first name is missing')
            }
            if (this.lastname === '') {
                this.errors.push('The last name is missing')
            }

            if (this.password === '') {
                this.errors.push('The password is too short')
            }

            if (this.password !== this.password2) {
                this.errors.push('The passwords don\'t match')
            }

            if (!this.errors.length) {
                const formData = {
                    username: this.email,
                    email: this.email,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    password: this.password,
                    re_password: this.password
                }

                axios
                    .post("/api/v1/users/", formData)
                    .then(response => {
                        console.log(response)
                        toast({
                            message: 'Account created, please log in!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })

                        const toPath = this.$route.query.to || '/log-in'

                        this.$router.push(toPath)
                    })
                    .catch(error => {
                        if (error.response) {
                            for (const property in error.response.data) {
                                this.errors.push(`${property}: ${error.response.data[property]}`)
                            }

                            console.log(JSON.stringify(error.response.data))
                        } else if (error.message) {
                            this.errors.push('Something went wrong. Please try again')
                            
                            console.log(JSON.stringify(error))
                        }
                    })
            }
        }
    }
}
</script>

<style scoped>
.is-success {
    background-color:#004100;
}
</style>