<template v-if="block">
    <li v-bind:value="JSON.stringify({
        id:block.id,
        name:block.name,
        project:block.project,
        number:block.number,
        number_required:block.number_required
    })">
        <span class="is-size-5">{{ block.name }}</span> 
        <p class="is-size-5">{{ block.number }} of {{ block.number_required }}</p>
        <progress class="progress is-success" :value="block.number / block.number_required * 100" max="100">
        </progress>
        <p class="is-size-5">{{ Math.ceil(block.number / block.number_required * 100) }}% Complete </p>
          <div class="field mt-3">
          <div class="columns is-12 is-mobile">
            <div class="column is-10" @click="toggleContentBlockMade">
              <label class="label">Update number of blocks</label>
            </div>
            <div class="arrow-container column is-2" @click="toggleContentBlockMade">
              <span class="arrow-icon" :class="{ 'up': showContentBlockMade, 'down': !showContentBlockMade }"></span>
            </div>
          </div>
          <div class="control content" v-show="showContentBlockMade">
              <input class="input mb-1" type="number" placeholder="Number" min="0"  name="number" v-model="quantity">
              <button class="button is-success" type="submit" @click="updateData()">Update Blocks</button>
          </div>
        </div>
          <hr class="">
          <div class="columns is-12 is-mobile">
          <div class="column is-10" @click="toggleContentBlock">
            <p>Daily production details</p>
          </div>
          <div class="arrow-container column is-2" @click="toggleContentBlock">
              <span class="arrow-icon" :class="{ 'up': showContentBlock, 'down': !showContentBlock }"></span>
          </div>
          </div> 
          <div class="control content" v-show="showContentBlock">
            <table class="table">
            <thead>
              <th>Quantity</th>
              <th>Date</th>
            </thead>
              <tr v-for="block_made in this.$store.state.blocks_made.filter(block_made => block_made.block === block.id)" :key="block_made.id">
              <td>{{ block_made.quantity }}</td>
              <td><span class="pr-4">{{(block_made.date_created).substring(0,10)}}</span> {{ (block_made.date_created).substring(11, 19) }}</td>
              </tr>
            </table>
          </div>
        <hr>
    </li>
  </template>
  
  <script>
  import axios from 'axios';
  import { toast } from 'bulma-toast'
  export default {
    name: 'Block',
    props:{
        block:Object
    },
    data() {
      return {
        quantity:'',
        showContentBlock: false,
        showContentBlockMade: false,
      };
    },
    methods: {
      updateData(){
        if (isNaN(this.quantity) || this.quantity < 1){
                this.quantity = 0
        }
        const data = { number: this.quantity} 
        console.log(data)
        const data1 = {
            block: this.block.id,
            quantity: this.quantity
          }
        console.log(data1)

        const data2 = { number: this.block.blocks_from_one_stone_dust_tonne}
        axios
          .post('/api/v1/upload_blocks_made/', data1)
          .then(response => {
            console.log(response)
          })
        axios.post(`/api/v1/blocks/${this.block.id}/update`, data)
            .then(response => {
              this.quantity = ''
              toast({
                  message: 'The Block number was successfully added',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
              })
              console.log(response);
            })
            .catch(error => {
              console.log(error);
            });
      },

      toggleContentBlock(){
        this.showContentBlock = !this.showContentBlock;
      },
      toggleContentBlockMade(){
        this.showContentBlockMade = !this.showContentBlockMade;
      },
    },
  };
  </script>

<style scoped>
.arrow-icon {
  display: inline-block;
  border: solid black;
  border-width: 0 3px 3px 0;
  padding: 3px;
  margin-right: 10px;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out;
}

.arrow-icon.up {
  transform: rotate(-135deg);
}

.arrow-icon.down {
  transform: rotate(45deg);
}


</style>